import React from 'react';
import { Row, Col } from 'antd';
import './About.scss';

const About = () => {
  return (
    <div style={{ width: '1200px', marginRight: 'auto', marginLeft: 'auto' }}>
      <header className="page-header">
        <h1>3CC</h1>
        <Row gutter={20}>
          <Col span={16}>
            <div className="grid-content">
              <nav>
                <ul className="main-nav">
                  <li><a href="#">Management</a></li>
                  <li><a href="#">log</a></li>
                  <li><a href="#">Dashboard</a></li>
                </ul>
              </nav>
            </div>
          </Col>
          <Col span={2}>
            <div className="grid-content">
              <nav>
                <ul className="main-nav">
                  <li><a href="#">Acc</a></li>
                </ul>
              </nav>
            </div>
          </Col>
          <Col span={2}>
            <div className="grid-content">
              <nav>
                <ul className="main-nav">
                  <li><a href="#">Group</a></li>
                </ul>
              </nav>
            </div>
          </Col>
          <Col span={2}>
            <div className="grid-content">
              <nav>
                <ul className="main-nav">
                  <li><a href="#">Logout</a></li>
                </ul>
              </nav>
            </div>
          </Col>
        </Row>
      </header>
    </div>
  );
};

export default About;