export default {
  message: {
    success: "Success",
    warning: "Warning",
    fail: "Fail",
    logout_success: "Logout successfully",
    create_success: "Create successfully",
    create_fail: "Create unsuccessfully",
    create_cancel: "Create cancel",
    update_success: "Update successfully",
    update_fail: "Update unsuccessfully",
    delete_success: "Delete successfully",
    delete_fail: "Delete unsuccessfully",
    copy_success: "Copy successfully",
    leave_this_dialog: "Leave this dialog?",
    loading: "Loading...",
    uploading: "Uploading...",
    confirm_delete: "This will permanently delete. Continue?",
    logout_confirm: "This will force you to logout. Continue?",
    please_input: "Please input",
    confirm_password: "This will permanently change the password. Continue?",
    token_expired: "Your session has expired, please login again.",
    login_error: "Account or password has error.",
    service_error: "Service has error.",
    exceed_error: "The quantity has reached the limit.",
    device_include_in_group: "This device was used by group.",
    group_include_in_schedule: "This group was used by schedule.",
    cloud_file_include_in_schedule: "This cloud file was used by schedule."
  },
  button: {
    confirm: "Confirm",
    cancel: "Cancel",
    update: "Update",
    create: "Create",
    delete: "Delete",
    clear: "Clear",
    save: "Save",
    submit: "Submit",
    add: "Add",
    upload: "Upload",
    edit: "Edit",
    content: "Content",
    audition: "Audition",
    playing: "Playing",
    ready: "Ready",
    today: "Today",
    yesterday: "Yesterday",
    next_step: "Next",
    next_page: "Next",
    prev_step: "Prev",
    prev_page: "Back",
    search: "Search",
    select_all: "Select All"
  },
  menu: {
    dashboard: "Dashboard",
    broadcast: "Broadcast",
    setup: "Setup",
    hardware: "Hardware",
    schedule: "Schedule",
    content: "Schedule Content",
    cloud_file: "Cloud File",
    server: "Server",
    account: "Account",
    history: "History",
    change_password: "Change Password",
    logout: "Logout",
    create: "Create",
    update: "Update"
  },
  change_password_dialog: {
    title: "Change Password",
    new_password: "New Password",
    old_password: "Old Password",
    confirm_password: "Confirm",
    form_validate_old_password: "Please enter old password",
    form_validate_new_password: "Please enter new password",
    form_validate_new_password_1: "Length should be 5 to 16",
    form_validate_new_password_2:
      "Please enter at least one uppercase letter, one lowercase letter, one number and one special character in !@#$%^&*",
    form_validate_confirm_password: "Please confirm password",
    form_validate_confirm_password_1: "The password doesn't match"
  },
  login: {
    sign_in: "SIGN IN",
    forget_password: "Forget password",
    account: "Account",
    password: "Password",
    form_validate_account: "Please enter account",
    form_validate_password: "Please enter password",
    forget_password_dialog: "Forget Password Dialog",
    forget_password_message:
      "Please copy the authorization code and pass it to the supplier to get a new password"
  },
  broadcast: {
    breadcrumb_1: "Broadcast",
    breadcrumb_2: "Ready",
    group: "GROUP",
    single: "SINGLE",
    unused: "unused",
    selected: "selected",
    used: "used",
    normal: "normal",
    disable: "disable",
    target: "Target",
    type: "Type",
    type_list_1: "Immediately Broadcast",
    type_list_2: "Cloud File Broadcast",
    update_cloud_file: "Update cloud file",
    name: "Name",
    time: "Time",
    size: "Size",
    date: "Date",
    message: "Oops, You're end of the time limit.",
    owner_name_search: "Owner search",
    music_name_search: "Music name search"
  },
  dashboard: {
    device: "Device",
    schedule: "Schedule",
    storage: "Storage"
  },
  hardware: {
    group: "GROUP",
    single: "SINGLE",
    add_group: "Add group hardware",
    edit_group: "Edit group hardware",
    add_single: "Add single hardware",
    edit_single: "Edit single hardware",
    name: "Name",
    enable: "Enable",
    device_list: "Device List",
    sequence: "Sequence",
    ip: "IP",
    volume: "Volume",
    test_device: "Test Device",
    device_info: "Device Info",
    product_name: "Product Name",
    serial_number: "Serial Number",
    wait_until_audio_finished: "Please wait until the audio sound finished",
    upper_data_error_message: "Upper data has error, please check again",
    form_validate_name: "Please input name",
    form_validate_name_1: "Length should be 5 to 16",
    form_validate_name_2: "This name can't use",
    form_validate_name_3:
      "This group name already exists. Use a different name.",
    form_validate_enable: "Please enter enable",
    form_validate_device_list: "Please enter device list",
    form_validate_ip: "Please input IP address",
    form_validate_ip_1: "Length should be less then 48",
    form_validate_ip_2: "Please enter valid IP",
    form_validate_ip_3: "This IP can't use",
    form_validate_volume: "Please enter volume"
  },
  cloud: {
    owner_name_search: "Owner search",
    music_name_search: "Music name search",
    storage_size: "Storage Size",
    used_space: "Used Space",
    free_space: "Free Space",
    cloud_list: "Cloud List",
    name: "Name",
    time: "Time",
    size: "Size",
    date: "Date",
    message:
      'Please click "Select File", select the audio source file that matches the playback format from the computer.',
    select_file: "Select File",
    reselect_file: "Reselect File",
    upload_file: "Upload File",
    rename: "Rename",
    file_name: "File Name",
    preview: "Preview",
    form_validate_file_name: "Please enter file name",
    form_validate_file_name_1:
      "Please enter correct file extension. (mp3, wav)",
    form_validate_file_name_2:
      "This name is duplicated, please enter another name",
    form_validate_file: "Please upload audio file first",
    form_validate_file_1: "Please upload mp3 or wav audio file"
  },
  schedule: {
    owner_name_search: "Owner search",
    music_name_search: "Music name search",
    preview_warning:
      "Before you do anything else, please close the music preview first",
    previewing: "Broadcast",
    file_name_error: "File name error",
    schedule_list: "Schedule List",
    name: "Name",
    description: "Description",
    start_time: "Start Time",
    end_time: "End Time",
    to: "To",
    target: "Target",
    create_at: "Created At",
    music: "Music",
    enable: "Enable",
    disable: "Disable",
    message: "Please enter your schedule target and click next.",
    music_list: "Music List",
    time: "Time",
    size: "Size (bytes)",
    date: "Date",
    interval: "Interval (Minutes)",
    period: "Period",
    color: "Schedule Color",
    select_time: "Select Time",
    form_time_search_placeholder: "Please enter time",
    form_validate_name: "Please enter name",
    form_validate_name_1: "Length should be 5 to 16",
    form_validate_name_2: "This name had been used",
    form_validate_date: "Please select at least one date",
    form_validate_time: "Please select time",
    form_validate_time_1: "Start time and end time cannot be the same",
    form_validate_period: "Please select period",
    form_validate_interval: "Please select interval",
    form_validate_color: "Please select background color",
    redirect_warning: "* This page is not editable, please redirect to ",
    redirect_page: "this",
    minutes: "mins",
    schedule_conflict:
      "This schedule has conflicted with other schedule, do you still want to add this schedule?",
    content: "Schedule Content"
  },
  server: {
    account: "Hardware account",
    password: "Hardware password",
    storage_warning_limit: "Storage warning limit",
    broadcast_start_sound: "Broadcast start sound",
    broadcast_end_sound: "Broadcast end sound",
    broadcast_time_limit: "Broadcast time limit (seconds)",
    reboot_the_server: "Reboot the server",
    restart_server: "Restart Server",
    form_validate_storage: "Please enter storage warning limit",
    form_validate_start_sound: "Please select sound",
    form_validate_end_sound: "Please select sound",
    form_validate_time_limit: "Please enter broadcast time limit",
    form_validate_account: "Please input hardware account",
    form_validate_account_1: "Length should be 5 to 10",
    form_validate_account_2: "Please enter English or number",
    form_validate_password: "Please input hardware password",
    form_validate_password_1: "Length should be 5 to 10",
    form_validate_password_2:
      "Please enter at least one uppercase, one lowercase letter and one digital number",
    message: "Restart server successfully"
  },
  account: {
    account_list: "Account List",
    edit: "Edit",
    account: "Account",
    role: "Role",
    login_time: "Login Time",
    activation_time: "Activation Time",
    enabled: "Enabled",
    password: "Password",
    confirm_password: "Confirm",
    competence: "Competence",
    group: "GROUP",
    breadcrumb_1: "Update",
    breadcrumb_2: "Create",
    form_validate_account: "Please enter account",
    form_validate_account_1: "Length should be 5 to 16",
    form_validate_account_2: "This account had been used",
    form_validate_competence: "Please enter competence",
    form_validate_password: "Please enter password",
    form_validate_password_1: "Length should be 5 to 16",
    form_validate_new_password_2:
      "Please enter at least one uppercase letter, one lowercase letter, one number and one special character in !@#$%^&*",
    form_validate_confirm_password: "Please confirm password",
    form_validate_confirm_password_1: "The password doesn't match",
    form_validate_enable: "Please enter enable status"
  },
  history: {
    search: "Search",
    time: "Time",
    account: "Account",
    role: "Role",
    created_at: "Time",
    action: "Action",
    result: "Result",
    to: "To",
    start_date: "Start Date",
    end_date: "End Date"
  }
}
