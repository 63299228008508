import { createSlice } from '@reduxjs/toolkit';
import { addTask } from '../api/generate';

const initialState = {
  queue: [],
  progress: {},
};

const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setQueue: (state, action) => {
      state.queue = action.payload;
    },
    addProgress: (state, action) => {
      const { queue, progress, title } = action.payload;
      state.progress[queue] = { progress, title };
    },
    removeProgress: (state, action) => {
      const queue = action.payload;
      delete state.progress[queue];
    },
    cancelProgress: (state, action) => {
      const { cancel, queue } = action.payload;
      cancel();
      delete state.progress[queue];
    },
  },
});

export const { setQueue, addProgress, removeProgress, cancelProgress } = uploadSlice.actions;

export const uploadFile = (file) => async (dispatch, getState) => {
  try {
    const queue = getState().upload.queue;
    const formData = new FormData();
    formData.append('title', file.name);
    formData.append('file', file);

    dispatch(setQueue([...queue, file.name]));

    const response = await addTask(formData);
    dispatch(removeProgress(file.name));
    // 其他上傳成功後的操作...
  } catch (error) {
    console.error('Error uploading file:', error);
    dispatch(cancelProgress({ cancel: error.cancel, queue: file.name }));
    // 其他上傳失敗後的操作...
  }
};

export default uploadSlice.reducer;