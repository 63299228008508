import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import zhTW from 'antd/lib/locale/zh_TW';
import App from './App';
import store from './store';
import './locales';
import { createLogger } from 'redux-logger';

const logger = createLogger({
  collapsed: true,
  duration: true,
  level: 'debug',
  // 其他選項...
});

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ConfigProvider locale={zhTW}>
        <App />
      </ConfigProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);
