import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';

export const login = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/login`, data);
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

export const logout = async () => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/logout`);
    return response.data;
  } catch (error) {
    console.error('Error logging out:', error);
    throw error;
  }
};

export const getUserInfo = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/v1/management/users`);
    return response.data;
  } catch (error) {
    console.error('Error getting user info:', error);
    throw error;
  }
};

export const createGroup = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/groups`, data);
    return response.data;
  } catch (error) {
    console.error('Error creating group:', error);
    throw error;
  }
};

export const createUser = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/management/users`, data);
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export const deleteUser = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/management/user/delete`, data);
    return response.data;
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

export const joinGroup = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/groups/joingroup`, data);
    return response.data;
  } catch (error) {
    console.error('Error joining group:', error);
    throw error;
  }
};

export const moveOutGroup = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/groups/moveoutgroup`, data);
    return response.data;
  } catch (error) {
    console.error('Error moving out of group:', error);
    throw error;
  }
};

export const moveGroupUser = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/groups/movegroupuser`, data);
    return response.data;
  } catch (error) {
    console.error('Error moving group user:', error);
    throw error;
  }
};

export const resetAccount = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/management/users/resetAccount`, data);
    return response.data;
  } catch (error) {
    console.error('Error resetting account:', error);
    throw error;
  }
};

export const changeAccPwd = async (data) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/v1/management/users/profiles`, data);
    return response.data;
  } catch (error) {
    console.error('Error changing account password:', error);
    throw error;
  }
};

export const getGroupInfo = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/v1/groups/groupinfo`);
    return response.data;
  } catch (error) {
    console.error('Error getting group info:', error);
    throw error;
  }
};

export const getGroupList = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/v1/groups`);
    return response.data;
  } catch (error) {
    console.error('Error getting group list:', error);
    throw error;
  }
};

export const deleteGroup = async (data) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/v1/groups`, { data });
    return response.data;
  } catch (error) {
    console.error('Error deleting group:', error);
    throw error;
  }
};

export const lockAcc = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/management/users/lockAccount`, data);
    return response.data;
  } catch (error) {
    console.error('Error locking account:', error);
    throw error;
  }
};

export const getLockUserList = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/v1/management/getlockuserlist`);
    return response.data;
  } catch (error) {
    console.error('Error getting locked user list:', error);
    throw error;
  }
};

export const fuzzySearchUser = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/management/fuzzySearchUser`, data);
    return response.data;
  } catch (error) {
    console.error('Error fuzzy searching user:', error);
    throw error;
  }
};

export const getUnsedUserList = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/v1/management/getunuseduserlist`);
    return response.data;
  } catch (error) {
    console.error('Error getting unused user list:', error);
    throw error;
  }
};

export const setDefaulPwd = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/dashboard/setdefaulpwd`, data);
    return response.data;
  } catch (error) {
    console.error('Error setting default password:', error);
    throw error;
  }
};

export const getBulletInfo = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/v1/dashboard/getbullet`);
    return response.data;
  } catch (error) {
    console.error('Error getting bullet info:', error);
    throw error;
  }
};

export const deleteBulletInfo = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/dashboard/deletebullet`, data);
    return response.data;
  } catch (error) {
    console.error('Error deleting bullet info:', error);
    throw error;
  }
};

export const createBulletContent = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/dashboard/createbullet`, data);
    return response.data;
  } catch (error) {
    console.error('Error creating bullet content:', error);
    throw error;
  }
};

export const editBulletContent = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/dashboard/editbullet`, data);
    return response.data;
  } catch (error) {
    console.error('Error editing bullet content:', error);
    throw error;
  }
};

export const checkAccountPwd = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/management/users/checkAccountPwd`, data);
    return response.data;
  } catch (error) {
    console.error('Error checking account password:', error);
    throw error;
  }
};

export const createCloudFile = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/cloud/files/upload`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating cloud file:', error);
    throw error;
  }
};

export const getCloudFileList = async (params) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/v1/cloud/files`, { params });
    return response.data;
  } catch (error) {
    console.error('Error getting cloud file list:', error);
    throw error;
  }
};

export const checkCloudFilePwd = async (params) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/v1/cloud/files/checkfilepwd`, { params });
    return response.data;
  } catch (error) {
    console.error('Error checking cloud file password:', error);
    throw error;
  }
};

export const deleteCloudFile = async (params) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/v1/cloud/files/cloudfile`, { params });
    return response.data;
  } catch (error) {
    console.error('Error deleting cloud file:', error);
    throw error;
  }
};

export const getMySelfInfo = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/v1/management/users/profiles`);
    return response.data;
  } catch (error) {
    console.error('Error getting self info:', error);
    throw error;
  }
};

export const getGroupMember = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/groups/getgroupmember`, data);
    return response.data;
  } catch (error) {
    console.error('Error getting group member:', error);
    throw error;
  }
};

export const backupDB = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/v1/dashboard/backupdb`, data);
    return response.data;
  } catch (error) {
    console.error('Error backing up database:', error);
    throw error;
  }
};