import { configureStore } from '@reduxjs/toolkit';
import uploadReducer from '../redux/uploadSlice';
import userReducer from '../redux/userSlice';

const store = configureStore({
  reducer: {
    upload: uploadReducer,
    user: userReducer,
  },
});

export default store;
