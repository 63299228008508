import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../redux/userSlice';
import bgImage from '../assets/bg.jpg';

const Home = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginFormRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loginForm, setLoginForm] = useState({
    account: process.env.REACT_APP_ENV === 'development' ? 'admin' : '',
    password: process.env.REACT_APP_ENV === 'development' ? '@Admin12' : '',
  });

  const userRole = useSelector((state) => state.user.userRole);
  const userChangePWD = useSelector((state) => state.user.userChangePWD);

  useEffect(() => {
    const handleLang = () => {
      const browserLang = navigator.language || navigator.userLanguage;
      if (browserLang === 'en-US') {
        i18n.changeLanguage('en');
      } else {
        i18n.changeLanguage('tw');
      }
    };

    handleLang();
  }, [i18n]);

  const handleLogin = () => {
    loginFormRef.current.validateFields().then((values) => {
      setLoading(true);
      dispatch(login(values))
        .then((action) => {
          if (login.fulfilled.match(action)) {
            if (userRole === 'administrator') {
              navigate('/management');
            } else {
              if (userChangePWD) {
                navigate('/changepwd');
              } else {
                navigate('/userhome');
              }
            }
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    });
  };

  return (
    <section className="login-block" style={{ backgroundImage: `url(${bgImage})` }}>
      <Form ref={loginFormRef}>
        <Form.Item
          name="account"
          rules={[{ required: true, message: t('login.account_required') }]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder={t('login.account')}
            value={loginForm.account}
            onChange={(e) => setLoginForm({ ...loginForm, account: e.target.value })}
            onPressEnter={handleLogin}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: t('login.password_required') }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder={t('login.password')}
            value={loginForm.password}
            onChange={(e) => setLoginForm({ ...loginForm, password: e.target.value })}
            onPressEnter={handleLogin}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            className="login-button"
            loading={loading}
            onClick={handleLogin}
          >
            {t('login.sign_in')}
          </Button>
        </Form.Item>
      </Form>
    </section>
  );
};

export default Home;
