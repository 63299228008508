import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';

// 導入相應的組件
import Home from './views/Home';
import About from './views/About';

const App = () => {
  return (
    <div id="app">
      <Routes>
        {/* 路由配置 */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        {/* 更多路由... */}
      </Routes>
    </div>
  );
};

export default App;