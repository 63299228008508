export default {
  message: {
    success: "成功",
    warning: "警告",
    fail: "失敗",
    logout_success: "登出成功",
    create_success: "新增成功",
    create_fail: "新增失敗",
    create_cancel: "新增取消",
    update_success: "更新成功",
    update_fail: "更新失敗",
    delete_success: "刪除成功",
    delete_fail: "刪除失敗",
    copy_success: "複製成功",
    leave_this_dialog: "是否關閉表單？",
    loading: "讀取中...",
    uploading: "上傳中...",
    confirm_delete: "是否永久刪除？",
    logout_confirm: "該動作會強制登出，是否繼續？",
    please_input: "請輸入",
    confirm_password: "執行後，原先密碼將會更動，是否執行？",
    token_expired: "登入時間過久請重新登入",
    login_error: "帳密有誤",
    service_error: "伺服器有誤",
    exceed_error: "數量已達上限",
    device_include_in_group: "該設備正被群組所使用",
    group_include_in_schedule: "該群組正被排程所使用",
    cloud_file_include_in_schedule: "該檔案被排程所使用",
  },
  button: {
    confirm: "確認",
    cancel: "取消",
    update: "更新",
    create: "創建",
    delete: "刪除",
    clear: "清除",
    save: "儲存",
    submit: "送出",
    add: "新增",
    upload: "上傳",
    edit: "編輯",
    content: "內容",
    audition: "試聽",
    playing: "播放中",
    ready: "準備",
    today: "今天",
    yesterday: "昨天",
    next_step: "下一步",
    next_page: "下一頁",
    prev_step: "上一步",
    prev_page: "上一頁",
    search: "搜尋",
    select_all: "全選",
  },
  menu: {
    dashboard: "統計表",
    broadcast: "廣播",
    setup: "廣播設置",
    hardware: "硬體設定",
    schedule: "排程設定",
    content: "排程內容",
    cloud_file: "雲端檔案設定",
    server: "伺服器設定",
    account: "帳號管理",
    history: "歷史紀錄",
    change_password: "更改密碼",
    logout: "登出",
    create: "創建",
    update: "編輯",
  },
  change_password_dialog: {
    title: "修改密碼",
    new_password: "新密碼",
    old_password: "舊密碼",
    confirm_password: "確認密碼",
    form_validate_old_password: "請輸入舊密碼",
    form_validate_new_password: "請輸入新密碼",
    form_validate_new_password_1: "長度請介於5-16",
    form_validate_new_password_2:
      "請輸入至少一個大寫英文，一個小寫英文，一個數字及一個特殊符號 !@#$%^&*",
    form_validate_confirm_password: "請確認密碼",
    form_validate_confirm_password_1: "密碼不同",
    form_validate_enable: "請選擇啟動",
  },
  login: {
    sign_in: "SiGN IN",
    forget_password: "忘記密碼",
    account: "使用者名稱",
    password: "密碼",
    form_validate_account: "請輸入帳號",
    form_validate_password: "請輸入密碼",
    forget_password_dialog: "忘記密碼表單",
    forget_password_message: "請複制授權碼並將其傳遞給供應商以獲取新密碼",
  },
  broadcast: {
    breadcrumb_1: "廣播系統",
    breadcrumb_2: "設定",
    group: "群組",
    single: "設備",
    ready: "準備",
    unused: "未使用",
    selected: "已選擇",
    used: "已使用",
    normal: "正常",
    disable: "停用",
    target: "廣播對象",
    type: "類型",
    type_list_1: "立即廣播",
    type_list_2: "雲端檔案廣播",
    update_cloud_file: "更新雲端檔案",
    name: "名稱",
    time: "檔案長度",
    size: "檔案大小",
    date: "上傳時間",
    message: "時間已用完",
    owner_name_search: "擁有者搜尋",
    music_name_search: "音樂名稱搜尋",
  },
  dashboard: {
    device: "設備",
    schedule: "排程",
    storage: "容量",
  },
  hardware: {
    group: "群組",
    single: "設備",
    add_group: "新增群組",
    add_single: "新增設備",
    edit_group: "編輯群組",
    edit_single: "編輯設備",
    name: "名稱",
    enable: "啟用",
    device_list: "硬體清單",
    sequence: "編號序列",
    ip: "網路位址",
    volume: "音量",
    test_device: "測試裝置",
    device_info: "進階資訊",
    product_name: "裝置名稱",
    serial_number: "裝置序號",
    wait_until_audio_finished: "測試檔案尚未播放完畢，請稍等",
    upper_data_error_message: "上方資訊有誤，請修改後再測試",
    form_validate_name: "請輸入名稱",
    form_validate_name_1: "長度請介於5-16",
    form_validate_name_2: "這名稱無法使用",
    form_validate_enable: "請輸入是否啟用",
    form_validate_device_list: "請輸入硬體清單",
    form_validate_ip: "請輸入IP",
    form_validate_ip_1: "長度請小於48",
    form_validate_ip_2: "請輸入有效IP",
    form_validate_ip_3: "這IP無法使用",
    form_validate_volume: "請輸入音量",
  },
  cloud: {
    owner_name_search: "擁有者搜尋",
    music_name_search: "音樂名稱搜尋",
    storage_size: "儲存容量大小",
    used_space: "已使用空間",
    free_space: "未使用空間",
    cloud_list: "雲端檔案清單",
    name: "名稱",
    time: "檔案長度",
    size: "檔案大小",
    date: "上傳時間",
    message: "請單擊“選擇檔案“，從電腦中選擇與播放格式符合的音樂檔案。",
    select_file: "選擇檔案",
    reselect_file: "重新選擇檔案",
    upload_file: "上傳檔案",
    rename: "重新命名",
    file_name: "檔案名稱",
    preview: "音樂試聽",
    form_validate_file_name: "請輸入檔案名稱",
    form_validate_file_name_1: "請輸入正確檔案副檔名 (mp3, wav)",
    form_validate_file_name_2: "該檔案名稱已被使用，請輸入其他檔案名稱",
    form_validate_file: "請先上傳檔案",
    form_validate_file_1: "請上傳mp3或wav檔",
  },
  schedule: {
    owner_name_search: "擁有者搜尋",
    music_name_search: "音樂名稱搜尋",
    preview_warning: "請先停止試播，才可進行其他操作",
    previewing: "試播中",
    file_name_error: "檔案名稱有誤",
    schedule_list: "排程清單",
    name: "排程名稱",
    description: "描述",
    start_time: "起始時間",
    end_time: "結束時間",
    to: "至",
    target: "廣播對象",
    create_at: "創建時間",
    music: "音檔",
    enable: "啟用",
    disable: "停用",
    message: "請輸入廣播對象，然後點擊下一步",
    music_list: "音檔清單",
    time: "時間",
    size: "檔案大小 (bytes)",
    date: "一週時間",
    interval: "音檔間隔 (分鐘)",
    period: "週期",
    color: "計畫表背景顏色",
    select_time: "請選擇時間",
    form_time_search_placeholder: "請輸入日期",
    form_validate_name: "請輸入名稱",
    form_validate_name_1: "長度請介於5-16",
    form_validate_name_2: "該名稱已被使用",
    form_validate_date: "請輸入至少一天",
    form_validate_time: "請輸入時間",
    form_validate_time_1: "起始時間不可等於結束時間",
    form_validate_period: "請輸入周期",
    form_validate_interval: "請輸入間隔",
    form_validate_color: "請輸入背景顏色",
    redirect_warning: "* 此頁面不可進行修改，欲修改請",
    redirect_page: "點擊",
    minutes: "分鐘",
    schedule_conflict: "排程有衝突，您仍要繼續新增嗎？",
    content: "排程內容",
  },
  server: {
    account: "裝置預設帳號",
    password: "裝置預設密碼",
    storage_warning_limit: "容量告警",
    broadcast_start_sound: "廣播起始聲音",
    broadcast_end_sound: "廣播結束聲音",
    broadcast_time_limit: "廣播長度限制 (秒)",
    reboot_the_server: "重啟伺服",
    restart_server: "重啟",
    form_validate_storage: "請輸入容量告警",
    form_validate_start_sound: "請選擇廣播起始聲音",
    form_validate_end_sound: "請選擇廣播結束聲音",
    form_validate_time_limit: "請輸入廣播長度限制",
    form_validate_account: "請輸入設備帳號",
    form_validate_account_1: "長度請介於5-10",
    form_validate_account_2: "請輸入英文或數字",
    form_validate_password: "請輸入設備密碼",
    form_validate_password_1: "長度請介於5-10",
    form_validate_password_2: "請輸入至少一個大寫英文，一個小寫英文及一個數字",
    message: "重啟伺服器成功",
  },
  account: {
    breadcrumb: "帳號設定",
    account_list: "帳號清單",
    edit: "編輯",
    account: "帳號名稱",
    role: "角色",
    login_time: "登入時間",
    activation_time: "啟用時間",
    enabled: "啟用",
    password: "密碼",
    confirm_password: "確認密碼",
    competence: "權限",
    group: "群組",
    breadcrumb_1: "編輯",
    breadcrumb_2: "創建",
    form_validate_account: "請輸入帳號",
    form_validate_account_1: "長度請介於5-16",
    form_validate_account_2: "該帳號名稱已被使用",
    form_validate_competence: "請選擇權限",
    form_validate_password: "請輸入密碼",
    form_validate_password_1: "長度請介於5-16",
    form_validate_password_2:
      "請輸入至少一個大寫英文，一個小寫英文，一個數字及一個特殊符號 !@#$%^&*",
    form_validate_confirm_password: "請確認密碼",
    form_validate_confirm_password_1: "密碼不同",
  },
  history: {
    breadcrumb: "歷史紀錄",
    search: "搜尋表單",
    time: "時間",
    account: "帳號",
    role: "角色",
    created_at: "執行時間",
    action: "執行動作",
    result: "搜尋結果",
    to: "至",
    start_date: "開始日期",
    end_date: "結束日期",
  },
};
